export const CARBANK_AR = 'carbank_ar' as const
export const CARBANK_CL = 'carbank_cl' as const
export const CASANUESTRA = 'casanuestra' as const
export const CASANUESTRA_DEV = 'casanuestra_dev' as const
export const CASANUESTRA_STG = 'casanuestra_stg' as const
export const CBANK = 'cbank' as const
export const CBROKER = 'cbroker' as const
export const DEMO_AR = 'demo_ar' as const
export const DEMO_CL = 'demo_cl' as const
export const HSP = 'hsp' as const
export const HSP_DEV = 'hsp_dev' as const
export const HSP_STG = 'hsp_stg' as const
export const LOCAL_CBANK = 'local_cbank' as const
export const MAF = 'maf' as const
export const MAF_DEV = 'maf_dev' as const
export const MAF_STG = 'maf_stg' as const
export const MAF_UAT = 'maf_uat' as const
export const MYV = 'myv' as const
export const PRESCIENTSECURITY1 = 'prescientsecurity1' as const
export const SUPV = 'supv' as const
export const SUPV_DEV = 'supv_dev' as const
export const SUPV_SME = 'supv_sme' as const
export const SUPV_SME_DEV = 'supv_sme_dev' as const
export const SUPV_STG = 'supv_stg' as const
export const SUPV_SME_STG = 'supv_sme_stg' as const

export const TENANTS = {
  [CARBANK_AR]: CARBANK_AR,
  [CARBANK_CL]: CARBANK_CL,
  [CASANUESTRA_DEV]: CASANUESTRA_DEV,
  [CASANUESTRA_STG]: CASANUESTRA_STG,
  [CASANUESTRA]: CASANUESTRA,
  [CBANK]: CBANK,
  [CBROKER]: CBROKER,
  [DEMO_AR]: DEMO_AR,
  [DEMO_CL]: DEMO_CL,
  [HSP_DEV]: HSP_DEV,
  [HSP_STG]: HSP_STG,
  [HSP]: HSP,
  [LOCAL_CBANK]: LOCAL_CBANK,
  [MAF_DEV]: MAF_DEV,
  [MAF_STG]: MAF_STG,
  [MAF_UAT]: MAF_UAT,
  [MAF]: MAF,
  [MYV]: MYV,
  [PRESCIENTSECURITY1]: PRESCIENTSECURITY1,
  [SUPV_DEV]: SUPV_DEV,
  [SUPV_STG]: SUPV_STG,
  [SUPV]: SUPV,
  [SUPV_SME]: SUPV_SME,
  [SUPV_SME_DEV]: SUPV_SME_DEV,
  [SUPV_SME_STG]: SUPV_SME_STG
} as const

export default TENANTS
