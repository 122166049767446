/* eslint-disable @typescript-eslint/no-explicit-any */
import REGIONS from 'consts/regions'

import { FONT_SIZE_BUTTON_DEFAULT_AR, FONT_SIZE_BUTTON_DEFAULT_CL } from './button'

const BASE_STYLES: Record<string, any> = {
  [REGIONS.CL]: {
    base: {
      borderRadius: 'full',
      modal: {
        borderRadius: 16
      },
      card: {
        borderRadius: 16
      }
    },
    button: {
      fontSize: FONT_SIZE_BUTTON_DEFAULT_CL,
      height: '54px'
    },
    tag: { container: {} },
    skeleton: {},
    input: { field: {} },
    select: {
      field: {
        borderColor: 'transparent',
        border: 'none'
      }
    },
    modal: { dialog: {} },
    card: {
      container: {
        borderRadius: '20px'
      },
      header: {
        borderRadius: '20px'
      },
      body: {
        borderRadius: '20px'
      }
    }
  },
  [REGIONS.AR]: {
    base: {
      borderRadius: 8,
      modal: {
        borderRadius: 8
      },
      card: {
        borderRadius: 8
      }
    },
    button: {
      fontSize: FONT_SIZE_BUTTON_DEFAULT_AR,
      height: '54px'
    },
    tag: { container: {} },
    skeleton: {},
    input: { field: {} },
    select: {
      field: {
        borderColor: 'transparent',
        border: 'none'
      }
    },
    modal: { dialog: {} },
    card: {
      container: {
        borderRadius: '8px'
      },
      header: {
        borderRadius: '8px'
      },
      body: {
        borderRadius: '8px'
      }
    }
  }
}

export default function getBaseStyles(region: string) {
  return BASE_STYLES[region]
}
