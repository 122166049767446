import {
  CASANUESTRA_COLORS,
  CBANK_COLORS,
  DBANK_COLORS,
  DEFAULT_APP_COLORS,
  HSP_COLORS,
  MYV_COLORS,
  SBANK_COLORS,
  SUPERVIELLE_COLORS,
  DEMO_AR_COLORS,
  MAF_COLORS
} from '@consts/layoutConfig/colors'
import { type TenantsType } from '@consts/setupApp/tenants'
import TENANTS from '@consts/tenants'
import { mappTenant } from '@consts/tenants/front'
import { Palette } from 'types/colors'
const {
  casanuestra,
  casanuestra_dev,
  cbank,
  cbroker,
  demo_ar,
  demo_cl,
  hsp,
  hsp_dev,
  maf,
  myv,
  prescientsecurity1,
  supv,
  supv_sme
} = TENANTS

const tenantColors: { [key: string]: Palette } = {
  [cbank]: CBANK_COLORS,
  [cbroker]: DEFAULT_APP_COLORS,
  [hsp]: HSP_COLORS,
  [hsp_dev]: HSP_COLORS,
  [prescientsecurity1]: SBANK_COLORS,
  [myv]: MYV_COLORS,
  [demo_cl]: DBANK_COLORS,
  [casanuestra]: CASANUESTRA_COLORS,
  [casanuestra_dev]: CASANUESTRA_COLORS,
  [supv]: SUPERVIELLE_COLORS,
  [supv_sme]: SUPERVIELLE_COLORS,
  [demo_ar]: DEMO_AR_COLORS,
  [maf]: MAF_COLORS
}

export default function getColors(tenantProp: TenantsType): Palette {
  const tenant = mappTenant[tenantProp] || tenantProp

  return tenantColors[tenant] || DEFAULT_APP_COLORS
}
